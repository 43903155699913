import React, { useEffect, useState } from 'react';
import classes from './Home.module.css';
import backgroundImage from '../../assets/images/welcome-background.webp';
import mobileBackgroundImage from '../../assets/images/welcome-background-mobile.webp';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { scrollToSection } from '../../utils/scrollToSection';

const Home: React.FC = () => {
  const [bgImage, setBgImage] = useState(backgroundImage);
  const { t } = useTranslation();

  useEffect(() => {
    const updateBackgroundImage = () => {
      if (window.innerWidth <= 768) {
        setBgImage(mobileBackgroundImage);
      } else {
        setBgImage(backgroundImage);
      }
    };

    updateBackgroundImage();

    window.addEventListener('resize', updateBackgroundImage);

    return () => {
      window.removeEventListener('resize', updateBackgroundImage);
    };
  }, []);

  return (
    <section
      id="home"
      className={classes.home}
      style={{ backgroundImage: `url(${bgImage})` }}
    >
      <div className={classes.overlay}>
        <motion.div
          className={classes.text}
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
        >
          <h1>{t('Home.WelcomeMessage')}</h1>
          <p>{t('Home.WelcomeDescription')}</p>
          <motion.button
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.95 }}
            className={classes.ctaButton}
            onClick={() => scrollToSection('about')}
          >
            {t('Home.ScrollToNext')}
          </motion.button>
        </motion.div>
      </div>
    </section>
  );
};

export default Home;
