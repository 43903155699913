import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons';
import classes from './Footer.module.css';
import CookieSettings from '../Footer/Cookie/CookieSettings';

const Footer: React.FC = () => {
  const { t } = useTranslation();
  const [showScroll, setShowScroll] = useState(false);
  const [showSettings, setShowSettings] = useState(false);

  const checkScrollTop = useCallback(() => {
    if (!showScroll && window.pageYOffset > 400) {
      setShowScroll(true);
    } else if (showScroll && window.pageYOffset <= 400) {
      setShowScroll(false);
    }
  }, [showScroll]);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    window.addEventListener('scroll', checkScrollTop);
    return () => {
      window.removeEventListener('scroll', checkScrollTop);
    };
  }, [checkScrollTop]);

  return (
    <footer className={classes.footer}>
      <div className={classes.container}>
        <div className={classes.legalSection}>
          <p style={{ marginTop: '-1rem' }}>
            © {new Date().getFullYear()} {t('Footer.headline')}
          </p>
          <div className={classes.legalSectionInner}>
            <a href="/imprint" className={classes.legalLink}>
              {t('Footer.Imprint')}
            </a>
            <a href="/cookie-policy" className={classes.legalLink}>
              {t('Footer.Cookie Policy')}
            </a>
            <button
              className={classes.legalLinkButton}
              onClick={() => setShowSettings(true)}
            >
              {t('Footer.Cookie Settings')}
            </button>
            <a href="/privacy-policy" className={classes.legalLink}>
              {t('Footer.Privacy Policy')}
            </a>
            <a href="/terms-of-use" className={classes.legalLink}>
              {t('Footer.Terms of Service')}
            </a>
            <a href="https://benwal09.de" className={classes.legalLink}>{t('Footer.benwal09')}</a>
            {/* <p className={classes.legalLink}>{t('Footer.benwal09')}</p> */}
          </div>
        </div>
        {/* <div className={classes.socialSection}>
          <a
            href="https://facebook.com"
            className={classes.socialLink}
            aria-label="Facebook"
          >
            <FontAwesomeIcon icon={['fab', 'facebook']} />
          </a>
          <a
            href="https://instagram.com"
            className={classes.socialLink}
            aria-label="Instagram"
          >
            <FontAwesomeIcon icon={['fab', 'instagram']} />
          </a>
          <a
            href="https://twitter.com"
            className={classes.socialLink}
            aria-label="Twitter"
          >
            <FontAwesomeIcon icon={['fab', 'twitter']} />
          </a>
        </div> */}
      </div>
      <button
        onClick={scrollToTop}
        className={classes.scrollTop}
        style={{ display: showScroll ? 'flex' : 'none' }}
      >
        <FontAwesomeIcon icon={faChevronUp} />
      </button>
      {showSettings && (
        <CookieSettings onClose={() => setShowSettings(false)} />
      )}
    </footer>
  );
};

export default Footer;
