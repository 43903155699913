import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { motion, AnimatePresence } from 'framer-motion';
import classes from './Services.module.css';

const Services: React.FC = () => {
  const { t } = useTranslation();
  const [expandedIndex, setExpandedIndex] = useState<number | null>(null);

  const services = [
    {
      name: t('Services.service1.name'),
      duration: t('Services.service1.duration'),
      price: t('Services.service1.price'),
      description: t('Services.service1.description'),
    },
    {
      name: t('Services.service2.name'),
      duration: t('Services.service2.duration'),
      price: t('Services.service2.price'),
      description: t('Services.service2.description'),
    },
    {
      name: t('Services.service3.name'),
      duration: t('Services.service3.duration'),
      price: t('Services.service3.price'),
      description: t('Services.service3.description'),
    },
    {
      name: t('Services.service4.name'),
      duration: t('Services.service4.duration'),
      price: t('Services.service4.price'),
      description: t('Services.service4.description'),
    },
    {
      name: t('Services.service5.name'),
      duration: t('Services.service5.duration'),
      price: t('Services.service5.price'),
      description: t('Services.service5.description'),
    },
  ];

  const handleCardClick = (index: number) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  return (
    <section id="services" className={classes.services}>
      <h2>{t('Services.title')}</h2>
      <div className={classes.cardsContainer}>
        {services.map((service, index) => (
          <div
            key={index}
            className={`${classes.card} ${expandedIndex === index ? classes.expanded : ''}`}
            onClick={() => handleCardClick(index)}
          >
            <h3 className={classes.cardTitle}>{service.name}</h3>
            <p className={classes.cardDetail}>
              <strong>{t('Services.duration')}:</strong> {service.duration}
            </p>
            <p className={classes.cardDetail}>
              <strong>{t('Services.price')}:</strong> {service.price}
            </p>

            {/* Animation für die Beschreibung */}
            <AnimatePresence>
              {expandedIndex === index && (
                <motion.p
                  className={classes.cardDescription}
                  initial={{ height: 0, opacity: 0 }}
                  animate={{ height: 'auto', opacity: 1 }}
                  exit={{ height: 0, opacity: 0 }}
                  transition={{ duration: 0.5 }}
                >
                  {service.description}
                </motion.p>
              )}
            </AnimatePresence>

            <div className={classes.icon}>
              {expandedIndex === index ? '−' : '+'}
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Services;