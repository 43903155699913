import React from 'react';
import Slider from 'react-slick';
import classes from './Gallery.module.css';
import interior1 from '../../assets/images/gallery/interior-1.webp';
import interior2 from '../../assets/images/gallery/interior-2.webp';
import interior3 from '../../assets/images/gallery/interior-3.webp';
import interior4 from '../../assets/images/gallery/interior-4.webp';
import { useTranslation } from 'react-i18next';

const Gallery: React.FC = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
  };
  const { t } = useTranslation();

  return (
    <section id="gallery" className={classes.gallery}>
      <h2>{t('Gallery.title')}</h2>
      <Slider {...settings} className={classes.slider}>
        <div className={classes.imageContainer}>
          <img
            src={interior1}
            alt="The owner of the shop dressed in traditional clothing with a welcoming smile and hands in a 'wai' gesture."
            className={classes.image}
          />
        </div>
        <div className={classes.imageContainer}>
          <img
            src={interior2}
            alt="A massage room featuring two massage tables with traditional green and red mats."
            className={classes.image}
          />
        </div>
        <div className={classes.imageContainer}>
          <img
            src={interior3}
            alt="Warm-lit massage room showing two massage tables with ornate decorations."
            className={classes.image}
            />
        </div>
        <div className={classes.imageContainer}>
          <img
            src={interior4}
            alt="Massage room with a wooden bed, green mat, and rolled pillows placed neatly."
            className={classes.image}
          />
        </div>
      </Slider>
    </section>
  );
};

export default Gallery;
