import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import { scrollToSection } from '../../utils/scrollToSection';
import classes from './Header.module.css';
import deFlag from '../../assets/flags/de.svg';
import enFlag from '../../assets/flags/gb.svg';

const Header: React.FC = () => {
  const { t, i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
  };

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleScroll = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    id: string
  ) => {
    e.preventDefault();
    if (location.pathname !== '/') {
      navigate('/', { state: { scrollTo: id } });
    } else {
      scrollToSection(id);
    }
    if (isOpen) {
      toggleMenu();
    }
  };

  return (
    <header className={classes.header}>
      <a href="/" style={{ textDecoration: 'none' }}>
        <h1 className={classes.title}>{t('Title')}</h1>
      </a>
      <nav className={classes.nav}>
        <a
          href="#home"
          className={classes.navItem}
          onClick={(e) => handleScroll(e, 'home')}
        >
          {t('Home.title')}
        </a>
        <span className={classes.separator}>|</span>
        <a
          href="#about"
          className={classes.navItem}
          onClick={(e) => handleScroll(e, 'about')}
        >
          {t('About Us')}
        </a>
        <span className={classes.separator}>|</span>
        <a
          href="#services"
          className={classes.navItem}
          onClick={(e) => handleScroll(e, 'services')}
        >
          {t('Services.title')}
        </a>
        <span className={classes.separator}>|</span>
        <a
          href="#gallery"
          className={classes.navItem}
          onClick={(e) => handleScroll(e, 'gallery')}
        >
          {t('Gallery.title')}
        </a>
        <span className={classes.separator}>|</span>
        <a
          href="#direction"
          className={classes.navItem}
          onClick={(e) => handleScroll(e, 'direction')}
        >
          {t('Directions.title')}
        </a>
        <span className={classes.separator}>|</span>
        <a
          href="#contact"
          className={classes.navItem}
          onClick={(e) => handleScroll(e, 'contact')}
        >
          {t('Contact.title')}
        </a>
        <span className={classes.separator}>|</span>
        <a
          href="#faq"
          className={classes.navItem}
          onClick={(e) => handleScroll(e, 'faq')}
        >
          {t('FAQs.title')}
        </a>
      </nav>
      <div className={classes.languageSelector}>
        <img
          src={enFlag}
          className={classes.flag}
          alt="English"
          onClick={() => changeLanguage('en')}
        />
        <img
          src={deFlag}
          className={classes.flag}
          alt="German"
          onClick={() => changeLanguage('de')}
        />
      </div>
      <div className={classes.hamburger} onClick={toggleMenu}>
        <span
          className={
            isOpen
              ? `${classes.hamburgerLine} ${classes.open}`
              : classes.hamburgerLine
          }
        ></span>
        <span
          className={
            isOpen
              ? `${classes.hamburgerLine} ${classes.open}`
              : classes.hamburgerLine
          }
        ></span>
        <span
          className={
            isOpen
              ? `${classes.hamburgerLine} ${classes.open}`
              : classes.hamburgerLine
          }
        ></span>
      </div>
      {isOpen && <div className={classes.overlay} onClick={toggleMenu}></div>}
      <div className={`${classes.subnav} ${isOpen ? classes.open : ''}`}>
        <div className={classes.subnavInner}>
          <h1 style={{color:'var(--color-primary)',marginBottom:'2rem'}}>{t('Title')}</h1>
          <a
            href="#home"
            className={classes.navItem}
            onClick={(e) => handleScroll(e, 'home')}
          >
            {t('Home.title')}
          </a>
          <a
            href="#about"
            className={classes.navItem}
            onClick={(e) => handleScroll(e, 'about')}
          >
            {t('About Us')}
          </a>
          <a
            href="#services"
            className={classes.navItem}
            onClick={(e) => handleScroll(e, 'services')}
          >
            {t('Services.title')}
          </a>
          <a
            href="#gallery"
            className={classes.navItem}
            onClick={(e) => handleScroll(e, 'gallery')}
          >
            {t('Gallery.title')}
          </a>
          <a
            href="#direction"
            className={classes.navItem}
            onClick={(e) => handleScroll(e, 'direction')}
          >
            {t('Directions.title')}
          </a>
          <a
            href="#contact"
            className={classes.navItem}
            onClick={(e) => handleScroll(e, 'contact')}
          >
            {t('Contact.title')}
          </a>
          <a
            href="#faq"
            className={classes.navItem}
            onClick={(e) => handleScroll(e, 'faq')}
          >
            {t('FAQs.title')}
          </a>
        </div>
        <div className={classes.languageSelectorMobile}>
          <img
            src={enFlag}
            className={classes.flag}
            alt="English"
            onClick={() => changeLanguage('en')}
          />
          <img
            src={deFlag}
            className={classes.flag}
            alt="German"
            onClick={() => changeLanguage('de')}
          />
        </div>
      </div>
    </header>
  );
};

export default Header;
